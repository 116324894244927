import React from "react"
import LayoutRegular from "../components/layoutRegular"
import SEO from "../components/seo"
import StyledFullBackground from "../components/backgroundImage"
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const pageSEO = {title: "Contact", description: "Contact Benjamin Engeli via email or phone"}

export default function Contact({data}) {
  return (
    <StyledFullBackground imgName="alex">
    <LayoutRegular>
      <SEO title={pageSEO.title} description={pageSEO.description} />
      <div className="content">
        <h1>Contact</h1>
        <div className="divider" style={{marginBottom: '60px'}}></div>
        <p className="text">
          Benjamin Engeli <br />
          Phone: +41 61 535 8145 <br />
          <a href="mailto:info@benjaminengeli.com">info@benjaminengeli.com</a> <br /> <br />
          
          Links: <br />
          <a href="http://www.gershwinpianoquartet.com/" target="_blank" rel="noreferrer">www.gershwinpianoquartet.com</a> <br />
          <a href="https://www.ensemblekandinsky.com/" target="_blank" rel="noreferrer">www.ensemblekandinsky.com</a> <br /> <br /> <br />

          WEBDESIGN & PROGRAMMING: <br />
          <a href="https://lacreme.digital" target="_blank" rel="noreferrer">
          <div className="lacreme-logo-container"><Img fluid={data.file.childImageSharp.fluid} className="lacreme-logo" alt="LACREME digital" title="LACREME digital" /></div>
          </a>
          <a href="https://lacreme.digital" target="_blank" rel="noreferrer"><span style={{justifySelf: 'flex-start'}}>www.lacreme.digital</span></a>
        </p>
        <div className="divider" style={{marginTop: '40px'}}></div>
        <h2>Datenschutzerklärung & Impressum</h2>
        <p className="text" style={{paddingBottom: '2em', marginBottom: '0'}}>
          Verantwortlich im Sinne der Datenschutzgesetze ist der Webseiten-Inhaber, der unter "Impressum" aufgeführt ist.
          <br/><br/>
          <h5>AWStats</h5>
          Zur statistischen Auswertung der Webseite kommt der Open Source Log File Analyzer AWStats zum Einsatz. Dieser wertet die Logdateien des Webservers aus. Zwar sind diese Daten in der Regel nicht bestimmten Personen zuordenbar, dennoch enthalten die Logdateien Hostnames und IP-Adressen der Besucheranfragen. Diese Daten werden nicht an Dritte übermittelt und nicht mit anderen Datenquellen zusammengeführt sowie nach der statistischen Auswertung gelöscht. Die Logdateien befinden sich auf Servern in der Schweiz, wodurch eine serverseitige Übermittlung der Daten ins Ausland vermieden wird.
          <br /><br />
          <h5>YouTube</h5>
          Diese Webseite enthält eingebettete YouTube-Videos, welche Daten an YouTube übermitteln, eine Verbindung zu den Servern von YouTube herstellen und Cookies einsetzen sobald Sie ein YouTube-Video anklicken, bzw. abspielen. Datenschutzerklärung des YouTube-Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA: <a href="https://policies.google.com/privacy" style={{color: 'white'}}>https://policies.google.com/privacy</a>.
          </p>
          <h5>Impressum</h5>
          <p className="noselect" style={{fontSize: '14px', marginBottom: '40px'}} >
            info@benjaminengeli.com<br />
            Benjamin Engeli<br />
            Sämisweidstr. 10a<br />
            5035 Unterentfelden
          </p>
      </div>
    </LayoutRegular>
    </StyledFullBackground>
  )
}


export const query = graphql`
  query {
    file(relativePath: { eq: "assets/lacreme_digital_logo_white.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 250) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`